<template>
  <div @click="click" class="section__lk-content-item">
    <div class="section__lk-content-item-left">
      <img src="/pic/profile/address-book.svg" class="icon" />
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
          Материал
        </div>
        <div class="section__lk-content-item-data-content">
          {{ brochure.name }}
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right">
      <div class="flex-one">
        <img src="/pic/profile/file-download.svg" />
        <div>
          {{ brochure.size }} Мб
        </div>
      </div>
      <div class="section__lk-order-status"><div class="status status_download"></div></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SeminarRowBrochure",
  props: {
    brochure: {
      type: Object,
      default: null,
      required: true
    }
  },
  methods: {
    click() {
      window.open(this.brochure.file, '_blank');
    }
  }
}
</script>

<style scoped>

</style>