<template>
  <div v-if="data" class="section__lk-content-items">
    <seminar-row-data v-if="data" :data="data" />
    <seminar-row-test v-if="data.test && data.test.is_access" :test="data.test" />
    <seminar-row-brochure v-if="data.brochure" :brochure="data.brochure" />
    <seminar-row-certificate v-if="data.cert" :cert="data.cert" />
    <template v-if="data.event.is_tutor">
      <seminar-row-letter :data="data" />
      <seminar-row-stat :data="data" />
    </template>
  </div>
</template>

<script>
import SeminarRowData from "@/components/profile/seminar/SeminarRowData.vue";
import SeminarRowTest from "@/components/profile/seminar/SeminarRowTest.vue";
import SeminarRowBrochure from "@/components/profile/seminar/SeminarRowBrochure.vue";
import SeminarRowCertificate from "@/components/profile/seminar/SeminarRowCertificate.vue";
import SeminarRowLetter from "@/components/profile/seminar/SeminarRowLetter.vue";
import SeminarRowStat from "@/components/profile/seminar/SeminarRowStat.vue";

export default {
  name: "SeminarDataList",
  components: {
    SeminarRowStat,
    SeminarRowLetter, SeminarRowCertificate, SeminarRowBrochure, SeminarRowTest, SeminarRowData},
  props: ['data']
}
</script>

<style scoped>
</style>