<template>
  <div class="section__lk-content-item">
    <modal-test-start v-if="test" :test="test" />
    <div class="section__lk-content-item-left" @click="toTest()">
      <img src="/pic/profile/deadline.svg" class="icon" />
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
          {{ test.title }}
        </div>
        <div class="section__lk-content-item-data-content">
          {{ test.description }}
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right">
      <div class="flex-one">
        <div>
          <img src="/pic/profile/clock-eight.svg" /><br/>
          <img src="/pic/profile/check-square.svg" />
        </div>
        <div>
          {{ test.duration }} минут<br/>
          {{ test.questions_count }} вопросов
        </div>
      </div>
      <div class="section__lk-order-status"><div :class="'status status_approved'"></div></div>
    </div>
  </div>
</template>

<script>

import ModalTestStart from "@/components/modal/ModalTestStart.vue";
import $ from "jquery";

export default {
  name: "SeminarRowTest",
  components: {ModalTestStart},
  props: {
    test: {
      type: Object,
      default: null,
      required: true
    }
  },
  methods: {
    toTest() {
      if(this.test.user_results) {
        this.$router.push('/lk/test/' + this.test.id)
      } else {
        $('#test-start').modal('show')
      }
    }
  }
}
</script>

<style scoped>

</style>