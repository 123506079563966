import { render, staticRenderFns } from "./SeminarDataList.vue?vue&type=template&id=36e5b306&scoped=true&"
import script from "./SeminarDataList.vue?vue&type=script&lang=js&"
export * from "./SeminarDataList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e5b306",
  null
  
)

export default component.exports