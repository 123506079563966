<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Экспортные семинары</div>
    <template v-if="courses.length">
      <seminar-theme-filter :items="courses" v-model="course" />
      <div class="mt-6">
        <div class="d-flex">
          <label class="section__lk-label flex-one" for="seminar"><span>Выберите мероприятие</span></label>
          <create-event :course="course" v-if="$hasRole('ROLE_TUTOR') && course" />
        </div>
        <seminar-event-filter :items="seminars" v-model="seminar" />
        <seminar-event-data v-if="seminar" :seminar="seminar" />
      </div>
    </template>
    <div v-else-if="firstLoad" class="section__lk-empty">у вас нет доступа к семинарам</div>
    <template v-if="$route.name === 'SeminarStat'">
      <router-view />
    </template>
    <template v-else>
      <seminar-data-list v-if="seminarData" :data="seminarData" />
      <seminar-order-list v-if="orders" :orders="orders" />
    </template>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";
import SeminarDataList from "@/components/profile/seminar/SeminarDataList.vue";
import CreateEvent from "@/components/profile/seminar/CreateEvent.vue";
import SeminarOrderList from "@/components/profile/seminar/SeminarOrderList.vue";
import {eventBus} from "@/main";
import SeminarThemeFilter from "@/components/profile/seminar/SeminarThemeFilter.vue";
import SeminarEventFilter from "@/components/profile/seminar/SeminarEventFilter.vue";
import SeminarEventData from "@/components/profile/seminar/SeminarEventData.vue";
export default {
  name: "Seminars",
  components: {SeminarEventData, SeminarEventFilter, SeminarThemeFilter, SeminarOrderList, CreateEvent, SeminarDataList},
  data() {
    return {
      courses: [],
      seminars: [],
      course: null,
      seminar: null,
      seminarData: null,
      orders: null,
      firstLoad: false
    }
  },
  async mounted() {
    let id = this.$route.params.id
    this.courses = (await ApiService.get('intramuralenroll/courses')).data
    if (id) {
      this.seminarData = (await ApiService.get('intramuralenrolls/' + id)).data
      this.course = this.courses.find(course => this.seminarData.event.course.id === course.id)
      this.seminars = (await ApiService.get('intramuralenrolls/' + this.seminarData.event.course.id + '/events')).data
      this.seminar = this.seminars.find(seminar => seminar.id == id)
    } else {
      this.course = this.courses.length ? this.courses[0] : null
    }
    this.firstLoad = true
    eventBus.$on('event:create', async (id) => {
      this.seminarData = (await ApiService.get('intramuralenrolls/' + id)).data
      this.course = this.courses.find(course => this.seminarData.event.course.id === course.id)
      this.seminars = (await ApiService.get('intramuralenrolls/' + this.seminarData.event.course.id + '/events')).data
      this.seminar = this.seminars.find(seminar => seminar.id == id)
    })
  },
  watch: {
    async course(course) {
      if(course) {
        this.seminar = null
        this.seminarData = null
        this.seminars = []
        await this.loadSeminars(course.id)
      }
    },
    async seminar(event) {
      if(event) {
        this.seminarData = null
        this.seminarData = (await ApiService.get('intramuralenrolls/' + event.id)).data
        if(this.$hasRole('ROLE_TUTOR') && this.$route.name !== 'SeminarStat') {
          this.orders = (await ApiService.get('eventparticipants/' + event.id + '/all')).data
        }
      }
    }
  },
  methods: {
    async loadSeminars(courseId) {
      this.seminars = (await ApiService.get('intramuralenrolls/' + courseId + '/events')).data
      if(!this.seminar) {
        this.seminar = this.seminars[0]
      }
    }
  }
}
</script>

<style lang="scss">

</style>