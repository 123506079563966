<template>
  <router-link tag="div" :to="'/lk/seminars/' + data.event.id + '/stats'" class="section__lk-content-item">
    <div class="section__lk-content-item-left">
      <img src="/pic/profile/stats.svg" class="icon" />
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
          Статистика по мероприятию
        </div>
        <div class="section__lk-content-item-data-content">
          Пояснение статистики
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right">
      <div class="flex-one">
        <div class="d-flex">
          <img class="calendar" src="/pic/profile/message.svg" />
        </div>
        <u>
          Посмотреть
        </u>
      </div>
      <div class="d-flex text-grey align-items-center">
        <div class="section__lk-order-status">
          <div :class="'status status_user'"></div>
        </div>
        <div class="section__lk-order-count">
          {{ data.event.count_participants }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "SeminarRowStat",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>