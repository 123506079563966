<template>
  <div @click="toSeminar" class="section__lk-content-item">
    <div class="section__lk-content-item-left">
      <img src="/pic/profile/graduation-cap.svg" class="icon" />
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
          {{ seminar.api_type }}
        </div>
        <div class="section__lk-content-item-data-content">
          <div>{{ seminar.title }} <img src="/pic/profile/arrow-up-right.svg" /></div>
          <div v-if="seminar.is_without_tests && data.is_test_access && !data.was_review"><a :href="'/events/' + seminar.id + '/review'" class="btn btn-primary">Оставить отзыв</a></div>
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right">
      <img class="calendar" src="/pic/profile/calendar.svg" />
      <div>
        {{ seminar.date.start | formatDate('full') }}<br/>
        {{ seminar.date.end | formatDate('full') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SeminarRowData",
  props: {
    data: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      seminar: this.data.event
    }
  },
  methods: {
    toSeminar() {
      this.$router.push('/events/' + this.seminar.id).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>