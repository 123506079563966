import { render, staticRenderFns } from "./SeminarRowLetter.vue?vue&type=template&id=d484907a&scoped=true&"
import script from "./SeminarRowLetter.vue?vue&type=script&lang=js&"
export * from "./SeminarRowLetter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d484907a",
  null
  
)

export default component.exports