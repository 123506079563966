<template>
  <div>
    <v-select v-if="items && items.length" class="v-select-centered" :searchable="false" :clearable="false" id="model" :loading="!items" :disabled="!items.length" label="id" :options="items" v-model="model">
      <template slot="selected-option" slot-scope="event">
        <span class="selected-type">{{event.api_type}}:</span>&nbsp;{{ event.title }}
      </template>
      <template v-slot:option="item">
        <div class="text-grey" v-if="item.is_tutor">
          Вы преподаватель
        </div>
        <div>{{ item.title }}</div>
        <div class="text-dark">
          {{ item.date.start | formatDate('full') }} - {{ item.date.end | formatDate('full') }}
        </div>
        <div class="text-grey">
          {{ item.api_type }}
        </div>
      </template>
    </v-select>
    <div v-else>
      Нет доступных мероприятий
    </div>
  </div>
</template>

<script>
import selectFilterMixin from "@/mixins/selectFilterMixin";

export default {
  name: "SeminarEventFilter",
  mixins: [selectFilterMixin],
}
</script>

<style scoped>
.v-select .dropdown-toggle {
  min-height: 48px!important;
}
</style>