<template>
  <div v-if="orders" class="section__lk-content-items mt-0">
    <div v-if="orders.length" class="section__lk-content-items-title">
      Заявки
    </div>
    <template v-for="order in orders">
      <seminar-order-list-item :key="order.id" :order="order" />
    </template>
  </div>
</template>

<script>
import SeminarOrderListItem from "@/components/profile/seminar/SeminarOrderListItem.vue";

export default {
  name: "SeminarOrderList",
  components: {SeminarOrderListItem},
  props: {
    orders: {
      type: Array,
      default() {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.section__lk-content-items-title {
  background: #002E5E;
  padding: 3rem 4.8rem;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>