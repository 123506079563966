<template>
  <div>
    <div class="section__lk-under-label">
      <div class="section__lk-under-label-seminar-data">
        {{ seminar.date.start | formatDate('full') }} - <br class="d-lg-none d-md-none d-sm-none" /> {{ seminar.date.end | formatDate('full') }}
        <div class="section__lk-under-label-seminar-city d-none d-lg-block d-md-block" v-if="seminar.city">
          <img class="icon" src="/pic/profile/map-marker.svg" alt="map-marker" />{{ seminar.city.city_name_ru }}
        </div>
      </div>
      <div class="section__lk-under-label-seminar-count">
        <div>
          <img class="icon" src="/pic/profile/user.svg" alt="icon-user" />
        </div>
        <div>
          {{ seminar.count_participants ? seminar.count_participants : 0 }}
        </div>
      </div>
    </div>
    <div class="section__lk-under-label-seminar-city d-lg-none p-0 mt-2" v-if="seminar.city">
      <img class="icon" src="/pic/profile/map-marker.svg" alt="map-marker" />{{ seminar.city.city_name_ru }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SeminarEventData",
  props: {
    seminar: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>