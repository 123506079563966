<template>
  <div @click="click()" class="section__lk-content-item">
    <div class="section__lk-content-item-left">
      <img src="/pic/profile/diploma.svg" class="icon" />
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
          Сертификат № {{ cert.id }}
        </div>
        <div class="section__lk-content-item-data-content">
          {{ cert.course.seminar_name}}
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right">
      <div class="flex-one">
        <div class="d-flex">
          <img class="calendar" src="/pic/profile/calendar.svg" />
        </div>
        <div>
          Дата выдачи<br/>
          {{ cert.issue_date | formatDate('full_date_only') }}
        </div>
      </div>
      <div class="section__lk-order-status"><div :class="'status status_download'"></div></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SeminarRowCertificate",
  props: {
    cert: {
      type: Object,
      default: null,
      required: true
    }
  },
  methods: {
    click() {
      window.open(this.cert.cdn_pdf, '_blank');
    }
  }
}
</script>

<style scoped>

</style>