<template>
  <div class="test__info">
    <div class="row test">
      <div class="col-md-4 col-sm-4">
        <div class="test__info-item p-24">
          <img src="/pic/profile/icon-test-time.svg" />
          <div class="mt-24">
            {{ test.duration }} {{ test.duration | pluralize('ru', ['минута', 'минуты', 'минут']) }}
          </div>
        </div>
        <div class="test__info-item-desc">
          На тест отведено ограниченное количество времени
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="test__info-item p-24">
          <img src="/pic/profile/icon-test-count.svg" />
          <div class="mt-24">
            Вопросов: {{ questionsCount }}
          </div>
        </div>
        <div class="test__info-item-desc">
          Вопросы, на которые вы не успеете ответить, будут засчитаны как неправильные
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="test__info-item p-24">
          <img src="/pic/profile/icon-test-retry.svg" />
          <div class="mt-24">
            Пересдач: {{ test.retakes ? test.retakes : 'нет' }}
          </div>
        </div>
        <div class="test__info-item-desc">
          Внимательно проверьте свои ответы перед отправкой результатов
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestStartInfo",
  props: {
    test: {
      type: Object,
      required: true
    }
  },
  computed: {
    questionsCount() {
      return this.test.questions_count ? this.test.questions_count : (this.test.questionsCount ? this.test.questionsCount : 0);
    }
  }
}
</script>

<style scoped>

</style>