<template>
  <div class="doc fill-grey p-24 h-100">
    <div class="doc__content row">
      <div class="col-auto text-center">
        <div class="ext-wrapper m-0">
          <img src="/pic/icon-doc.svg"/>
          <div class="mt-1">PDF</div>
        </div>
      </div>
      <div class="col">
        <div class="doc__title">{{ title }}</div>
      </div>
    </div>
    <div class="doc__actions">
      <a :href="link" class="btn btn-outline-primary">Скачать</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentGray",
  props: ['link', 'title'],
}
</script>

<style scoped>

</style>